import {
  EXPERIMENTS,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import {
  isEditor,
  isPreview,
} from '../store/basic-params/basic-params-selectors';

export const getIsInIFrame = (state) => {
  const isInPreviewIFrame =
    isPreview(state) && isExperimentEnabled(state, EXPERIMENTS.PROD_OOI_EDITOR);
  const isInEditorIFrame =
    isEditor(state) && !isExperimentEnabled(state, EXPERIMENTS.OOI_IN_EDITOR);

  return isInPreviewIFrame || isInEditorIFrame;
};
