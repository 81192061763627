import React from 'react';
import { throttle } from 'lodash';
import PropTypes from 'prop-types';
import {
  EXPERIMENTS,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import Wix from '../../services/wix-sdk-polyfill';
import { isPreview } from '../../store/basic-params/basic-params-selectors';
import { connect } from '../runtime-context';

class ScrollListener extends React.Component {
  componentDidMount() {
    if (this.props.isPreview && this.props.isExperimentProdOOIEditorEnabled) {
      Wix.addEventListener(Wix.Events.SCROLL, this.handleScroll);
      return;
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll, false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isPreview === this.props.isPreview ||
      this.props.isExperimentProdOOIEditorEnabled
    ) {
      return;
    }

    if (this.props.isPreview) {
      Wix.addEventListener(Wix.Events.SCROLL, this.handleScroll);
    } else {
      Wix.removeEventListener(Wix.Events.SCROLL, this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.props.isPreview && this.props.isExperimentProdOOIEditorEnabled) {
      Wix.removeEventListener(Wix.Events.SCROLL, this.handleScroll);
      return;
    }

    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll, false);
    }
  }

  handleScroll = throttle((scroll) => this.props.onScroll(scroll), 500, {
    leading: true,
  });

  render() {
    return null;
  }
}

ScrollListener.propTypes = {
  onScroll: PropTypes.func.isRequired,
  isPreview: PropTypes.bool,
  isExperimentProdOOIEditorEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state) => ({
  isPreview: isPreview(state),
  isExperimentProdOOIEditorEnabled: isExperimentEnabled(
    state,
    EXPERIMENTS.PROD_OOI_EDITOR,
  ),
});

export default connect(mapRuntimeToProps)(ScrollListener);
