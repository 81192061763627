/**
 * Experiments created on Petri should be listed here.
 *
 * #### Scopes
 *
 * Any new created specs should be conducted via centralized conduction.
 *
 * This means that they should be created with the following scopes:
 *
 * |            | Scope                                              |
 * | :-         | :-                                                 |
 * | Backoffice | `bm-apps-14bcded7-0066-7c35-14d7-466cb3f09103`     |
 * | Viewer     | `viewer-apps-14bcded7-0066-7c35-14d7-466cb3f09103` |
 */
export var EXPERIMENTS = {
    USE_MEDIA: 'specs.wixBlog.useMedia',
    MERGE_EDITOR_APP: 'specs.wixBlog.mergeEditorApp',
    /** **OOI In Editor:** If `true` uses viewer controller in Editor */
    DISABLE_NOOP_CONTROLLER: 'specs.wixBlog.DisableNoopController',
    /** **OOI In Editor:** If `true` renders widgets in Editor */
    OOI_IN_EDITOR: 'specs.wixBlog.OOIInEditor',
    /** **OOI In Editor:** If `false` renders widgets in Editor Preview */
    PROD_OOI_EDITOR: 'specs.wixBlog.ProdOOIEditor',
};
